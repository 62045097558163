// override toast colors
.toast-success {
  color: darken($teal, 10%);
  background-color: lighten($teal, 50%);
  border: 1px solid lighten($teal, 35%);
}
.toast-warning {
  color: darken($orange, 20%);
  background-color: lighten($orange, 40%);
  border: 1px solid lighten($orange, 35%);
}

.toast-danger {
  color: darken($red, 20%);
  background-color: lighten($red, 40%);
  border: 1px solid lighten($red, 35%);
}

.toast-info {
  color: darken($blue, 20%);
  background-color: lighten($blue, 56%);
  border: 1px solid lighten($blue, 45%);
}
