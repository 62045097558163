@import 'vendors/mixins';
@import 'variables';
@import 'vendors/bootstrap';
@import 'partials/globals';
@import 'vendors/mixins';
@import 'vendors/fonts';
@import 'vendors/font-webshop/font-webshop';
@import 'vendors/ngx-pagination';
@import 'main';
@import 'partials/masterhead';
@import 'partials/sidenav';
@import 'partials/sidecart';
@import 'partials/cart';
@import 'partials/breadcrumb';
@import 'partials/buttons';
@import 'partials/starburst';
@import 'partials/waves';

// Lightbox
@import 'vendors/lightbox';

// Custom theme
@import 'vendors/material-theme';

// Toast alert import
//@import '../../../node_modules/ngx-toastr/toastr-bs4-alert';

// toast overrides
@import 'vendors/toastr-bs5-alert';
@import 'vendors/toast';

@import '~highlight.js/styles/github.css';

@import "~ngx-spinner/animations/ball-scale-multiple.css";
