.ngx-pagination {
  margin-bottom: 1rem !important;
  font-size: 1rem !important;

  @media screen and (max-width: 601px) {
    font-size: 1.5rem !important;
  }

  padding-right: 1.8em;

  //li {
  //  display: inline-block !important;
  //}

  // margin-left: 0 !important;
  //&::before {
  //  content: " " !important;
  //  display: table !important;
  //}
  //&::after {
  //  content: " " !important;
  //  display: table !important;
  //  clear: both !important;
  //}

  //&:first-child {
  //  a{
  //    @include border-radius($border-radius-sm 0 0 $border-radius-sm);
  //    &:before{
  //      border:none;
  //    }
  //  }
  //}

  .disabled {
    //background-color: $gray-200 !important;
    //color: $gray-100  !important;
    //width: 2em;
    //padding: 0.35rem 0 !important;

    //&::before {
    //  font-family: FontWebshop !important;
    //  content: "\f053" !important;
    //  //display: inline-block !important;
    //  //margin-right: 0rem !important;
    //}


    @media screen and (min-width: 601px) {
      display: none;
    }
  }



  a, .current {
    padding: 0.35rem 0 !important;
    width: 2em;
   // border-radius: $border-radius-sm !important;

  }

  a {
    background-color: lighten($info, 10%) !important;
   // border-left: 1px solid $info !important;
    // border-right: 1px solid $info !important;
    color: $white !important;
    border-left: 1px solid $info !important;
    //cursor: pointer !important;
    text-decoration: none;
    font-family: $headings-font-family;
    &:hover {
      background: $info !important;
      color: $white !important;
    }
  }

  .current {
    background: darken($info, 5%) !important;
    color: $white !important;
   // cursor: default !important;
    // border: 2px solid $gray-600 !important;
    font-family: $headings-font-family;
  }

  li {
    margin-left: 0 !important;
    margin-right: 0 !important;
    &:first-child {
      a {
        border-left: 0 !important;
      }
    }

  }

  //button {
  //  color: #0a0a0a !important;
  //  display: block !important;
  //  background-color: rgb(248, 248, 248) !important;
  //  color: black !important;
  //  border: 2px solid $gray-600 !important;
  //  padding: 0.5rem 1rem !important;
  //  border-radius: $border-radius-sm !important;
  //  cursor: pointer !important;
  //  &:hover {
  //    background: $gray-600 !important;
  //  }
  //}

  .pagination-previous {
    a {
      @include border-radius($border-radius-sm 0 0 $border-radius-sm);
      padding-left: .3em !important;
      &::before {
        font-family: FontWebshop !important;
        content: "\f053" !important;
        //display: inline-block !important;
        //margin-right: 0rem !important;
      }
      &::after {
        display: none;
      }

      margin-right: .5em;
      @include media-breakpoint-up(sm) {
        margin-right: 0;
      }

    }
  }
  .pagination-next {
    //padding-left: .5em !important;
    //padding-right: 0 !important;
    // padding-left: .3em !important;

    a {
      @include border-radius(0 $border-radius-sm $border-radius-sm  0);

      &::before {
        display: none;
      }

      &::after {
        font-family: FontWebshop !important;
        content: "\f054" !important;
        display: inline-block !important;
        //margin-left: 0rem !important;
      }

      margin-left: .5em;
      @include media-breakpoint-up(sm) {
        margin-left: 0;
      }
    }
  }

}
//@media screen and (max-width: 601px) {
//  .ngx-pagination.responsive {
//    .small-screen {
//      display: inline-block !important;
//      color: $black !important;
//      background-color: rgb(248, 248, 248) !important;
//      border: 2px solid $gray-600 !important;
//      padding: 0.5rem 1rem !important;
//      border-radius: $border-radius-sm !important;
//    }
//    li {
//      &:not(.small-screen) {
//        &:not(.pagination-previous) {
//          &:not(.pagination-next) {
//            display: none !important;
//          }
//        }
//      }
//    }
//  }
//}
