.starburst {
  height: 7.5em;
  width: 7.5em;
  background-color: $indigo;
  margin-top: -5em;

  padding: 0;

  @include media-breakpoint-up(lg) {
    height: 8.5em;
    width: 8.5em;
  }


  @include media-breakpoint-up(xl) {
    height: 7.5em;
    width: 7.5em;
    margin-top: -2em;
  }


  &:before, &:after {
    height: 7.5em;
    width: 7.5em;

    @include media-breakpoint-up(lg) {
      height: 8.5em;
      width: 8.5em;
    }

    @include media-breakpoint-up(xl) {
      height: 7.5em;
      width: 7.5em;
    }
    background-color: $indigo;
  }

  .text {
    height: 100%;
    width: 100%;
    background-color: $indigo;

    &:before, &:after {
      height: 100%;
      width: 100%;
      background-color: $indigo;

    }
  }

  &:before, &:after {
    content: "";
    position: absolute;
  }

  .text {
    &:before, &:after {
      content: "";
      position: absolute;
    }
  }

  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);

  &:before {
    top: 0;
    left: 0;
    -webkit-transform: rotate(-30deg);
    -moz-transform: rotate(-30deg);
    -ms-transform: rotate(-30deg);
    -o-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }

  &:after {
    top: 0;
    left: 0;
    -webkit-transform: rotate(-15deg);
    -moz-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    -o-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  .text {

    height: 7.5em;

    @include media-breakpoint-up(lg) {
      height: 8.5em;
    }

    @include media-breakpoint-up(xl) {
      height: 7.5em;
    }


    position: absolute;
    bottom: 0;
    right: 0;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 1;

    &:before {
      top: 0;
      left: 0;
      -webkit-transform: rotate(60deg);
      -moz-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
      -o-transform: rotate(60deg);
      transform: rotate(60deg);
    }

    &:after {
      top: 0;
      left: 0;
      -webkit-transform: rotate(75deg);
      -moz-transform: rotate(75deg);
      -ms-transform: rotate(75deg);
      -o-transform: rotate(75deg);
      transform: rotate(75deg);
    }

    span {
      position: relative;
      z-index: 100;
      display: block;
      text-align: center;
      color: $gray-100;
      font-size: 1rem;

      &.salesprice {
        padding-top: 1.8em;
        font-size: 1.2rem;
        line-height: 0;
      }

      &.offerprice {
        color: $white;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1em;
        white-space: nowrap;
      }

      &.normalprice {
        font-weight: 700;
        font-size: unset;
        // line-height: 1.5;
        color: $white;
        white-space: nowrap;
      }

      &.terms {
        font-size: .85rem;
        padding-top: .3em;
      }
    }
  }
}
