.masterhead {
 // background: linear-gradient(135deg, $blue 0%, $cyan 30%, $blue 50%, $cyan 70%, $blue 100% );
  background-color: $gray-200;

  color: $primary;

  padding: $spacer 0;

  @include flexbox();
  @include align-items(center);
  @include justify-content(center);

  @include media-breakpoint-up(lg) {
    padding-left: 0;
  }

  @include media-breakpoint-up(xxl) {
    padding-left: .5em;
  }

  @include media-breakpoint-up(xxxl) {
    padding-left: 1em;
  }

  //[class*="col-"] {
  //  padding: 0;
  //}


  //.card {
  //  border-bottom-left-radius: 0;
  //  border-bottom-right-radius: 0;
  //}
  //



  .card {
    margin-left: -20px;
    .card-title {
     // color: $pink;
      font-size: 1rem;
      margin-bottom: 0;
      text-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
      text-transform: uppercase;
    }

    .card-text {
      color: $white;
      font-size: 1.2rem;
      font-weight: 500;
    }

    .card-img-overlay {
      @include flexbox();
      @include justify-content(center);
      @include align-items(flex-end);
      @include flex-direction(row);
      text-align: center;
      padding-bottom: $grid-gutter-width;
    }
  }


  .badge {
   font-size: .7rem;
   height: 18px;
    @include box-shadow(0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12));
    //width: 18px;
    // position: relative !important;
  }

  //img[width] {
  //  width: auto; /* Defer to max-width */
  //}
  //img[width][height] {
  //  height: auto; /* Preserve aspect ratio */
  //}

  .logo {
    //padding-left: $grid-gutter-width / 2;
    //@include media-breakpoint-up(xl) {
    //  padding-left: $grid-gutter-width;
    //}

    cursor: pointer;
    height: 37px;
    margin-top: .15em;


    @include media-breakpoint-up(sm) {
      height: 46px;
      width: 147px;
    }


    @include media-breakpoint-up(lg) {
      margin-top: .55em;
      width: 120px;
      height: 37px;
    }

    @include media-breakpoint-up(xl) {
      margin-top: .55em;
      width: 147px;
      height: 46px;
    }

    @include media-breakpoint-up(xxl) {
      margin-top: .55em;
      width: 200px;
      height: 62px;
    }

    @include media-breakpoint-up(xxxl) {
      margin-top: .55em;
      width: 250px;
      height: 78px;
    }

    width: 120px;
      @include transition-all();
      &:hover {
        opacity: .8;
      }


  }

  .cart {
    color: $white;
    @include media-breakpoint-up(lg) {
      padding-left: $grid-gutter-width;
    }
    @include media-breakpoint-up(xl) {
      padding-right: $grid-gutter-width;
    }

    // don't work without important
    padding-top: .1em;


    .fa {
      font-size: 2.2rem;
    }

    //.fa-user-o {
    //  margin-top: .15em;
    //}

    .fa-heart-o {
      margin: 0 .55em .1em .35em;
    }

    .fa-reorder {
      margin: 0 0 0 .3em;
    }


    p {
      margin-bottom: .5em;
    }

    //.search-icon {
    //  margin: 0 0 0 .3em;
    //  color: $primary;
    //  background: lighten($primary, 15%);
    //}

    .fa-stack {

      margin: 0 1em .55em .3em;

      .fa-circle-o {
        font-size: 2.35rem;
      }

      .fa-search {
        font-size: 1.2rem;
        margin-left: .15em;
        margin-top: .15em;
      }

    }


    .menu-headline {
      display: block;
      font-size: .85rem;
      line-height: 1;
      margin-left: .5em;
      text-transform: uppercase;

      &:hover {
        color: $gray-600;
        cursor: pointer;
      }

      //&:first-child {
      //  padding-bottom: .2em;
      //  margin-bottom: .2em;
      //  line-height: .8;
      //  border-bottom: 1px solid $white;
      //}

    }


    .user-dropdown {
      //display: block;
      //position: absolute;
      // margin-top: 1em;
      color: $white;
      &:hover {
        color: $gray-600;
        cursor: pointer;
      }

      .fa-angle-down {
        font-size: 1rem;
      }

      span {
        padding: .5em;
        font-weight: 700;
        margin-left: 0;
        background-color: $secondary;
        color: $white;
        text-transform: uppercase;
        text-align: center;
      }
    }

    .dropdown-menu {
      background-color: $white;
    margin-left: -3.75em;
      border-radius: 0;

      .dropdown-item {
        padding: 0.2em 0.5em;
      }

      a {
        padding: .25em .5em;
        color: $black;
        font-family: $headings-font-family;
        font-size: .85rem;
        font-weight: 600;

        @include media-breakpoint-up(xxl) {
          font-weight: 100;
        }

        &:hover {
          color: $white;
        }

        &:before  {
          color: $blue;
          content: "\f054";
          display: inline-block;
          font-family: 'FontWebshop';
          font-size: .65rem;
          font-style: normal;
          font-weight: normal;
          padding-right: .25em;
          //-webkit-font-smoothing: antialiased;
          //-moz-osx-font-smoothing: grayscale;
        }
      }


      .fa {
        font-size: .65rem;
        margin-right: .1em;
      }
    }

  }

  a {
    color: $white;
    text-decoration: none;
    @include transition-all;
    &:hover {
      color: $gray-500;
    }
  }


// NAVBAR ///////////////////////////////////////////////////////

    .lead {
      font-family: $headings-font-family;
      font-size: 1.2rem;
      font-weight: 500;
      float: left;

      @include media-breakpoint-up(xl) {
        font-size: 1.3rem;
      }

      // line-height: 0;
    }


 .scrolled-navbar {
   background-color: $gray-300;
   height: 50px;
   left: 0;
   padding: .5em 0;
   position: fixed;
   right: 0;
   top: 0;
   z-index: 10;

   .navbar-nav {
     padding-bottom: 1.25em;
     padding-top: 0;

     .fa {
       color: $white;
     }



     .dropdown-toggle,  .btn-link {
       color: $white;
       &:hover {
         color: $gray-100;
       }
     }
   }

.collapse {
  background-color: $gray-300;
  margin-top: 40px;
}
 }


  .scrolled-cart {
    position: fixed;
    right: 0;
    top: .5em;

    @include media-breakpoint-up(lg) {
      top: 1.25em;
    }

    z-index: 11;
    .badge { margin-top: 0; }

  }

  .scrolled-logo {
    position: fixed;
    top: 0;
    z-index: 11;

    .phone-container {
      display: none;
    }

    .fa-mobile-phone {
      font-size: 2rem;
    }

    .lead {
      font-size: 1.1rem;
    }

    .logo {
      height: 40px;
      margin-top: .175em;
      width: 128px;

      @include media-breakpoint-up(lg) {
        margin-top: .75em;
        width: 147px;
        height: 46px;
      }

    }
    //img {
    //  height: 50px;
    //}

    @include media-breakpoint-up(sm) {
     // margin-top: -8px;
      width: 190px;
    }

    @include media-breakpoint-up(lg) {
      width: 180px;
    }

  }


.navbar {
  background-image: none;
  font-family: $headings-font-family;

  .btn {
    font-weight: 500;
    @include media-breakpoint-up(lg) {
      font-weight: 300;
    }
  }

  //.btn-link {
  //  color: $gray-900;
  //}

  .fa {
    color: $gray-800;
  }

  .fa-shopping-cart {
    margin-top: .1em;
  }

  .navbar-nav {
//    padding-bottom: 1.25em;
    padding-top: 0;
    .btn-group {

      @include media-breakpoint-up(lg) {
        margin-right: .2em;
      }

      @include media-breakpoint-up(xxl) {
        margin-right: .85em;
      }


      &:last-child {
        margin-right: 0;
      }
    }

    .show {
      background-color: $gray-500;

      .btn-link {
        color: $white;
      }

      .fa {
        color: $white;
      }
    }

// MEGA MENU POSITIONS


    #dropdown-women {
        .card-title {
          color: $pink;
        }
        h5 {
          a {
          color: $secondary;

            &:hover {
              color: $red-600;
            }
          }
        }

        ul {
          li {
            a {
              &:before {
                background-color: $secondary;
              }
            }
          }
        }

        @include media-breakpoint-up(lg) {
        margin-left: -26vw;
        .mega-content {
          padding: $grid-gutter-width $grid-gutter-width 0 $grid-gutter-width;
          width: 100vw;
        }
        .card-title {
          font-size: 2rem;
        }
      }

      @include media-breakpoint-up(xxl) {
        margin-left: -26vw;
        .mega-content {
          padding: $grid-gutter-width $grid-gutter-width 0 $grid-gutter-width;
          width: 86vw;
        }
        .card-title {
          font-size: 3rem;
        }
      }
      }

    #dropdown-girls {
      .card-title {
        color: $primary;
        font-size: 1rem;
        font-weight: 300;
      }
      h5 {
        a {
          color: $secondary;

          &:hover {
            color: $red-600;
          }
        }
      }

      ul {
        li {
          a {
            &:before {
              background-color: $secondary;
            }
          }
        }
      }

      @include media-breakpoint-up(lg) {
        margin-left: -15vw;
        .mega-content {
          padding: $grid-gutter-width;
          width: 56vw;
        }
        .card-title {
          font-weight: 100;
        }
      }
    }

    #dropdown-women, #dropdown-girls {
      background-color: $white;
      @include media-breakpoint-up(lg) {
      background: linear-gradient(135deg, $pink-200 0%, $red-100 30%, $pink-200 50%, $red-200 70%, $pink-500 100% );
      }
    }

    #dropdown-men, #dropdown-boys {
      background-color: $white;
      @include media-breakpoint-up(lg) {
        background: linear-gradient(135deg, $white 0%, $blue-100 30%, $white 50%, $blue-200 70%, $blue-300 100%);
      }
        // background-color: lighten($success, 15%);
        .card-title {
          color: $blue;
          font-size: 1rem;
          font-weight: 300;
        }
        h5 {
          color: $blue-600;
          font-weight: 500;
          a {
            color: $blue-600;

            &:hover {
              color: $blue-700;
            }
          }
        }


        ul {
          li {
            a {
              &:before {
                background-color: $blue-500;
              }
            }
          }
        }

        @include media-breakpoint-up(lg) {
          margin-left: -15vw;
          .mega-content {
            padding: $grid-gutter-width;
            width: 56vw;
          }
          .card-title {
            font-size: 2rem;
            font-weight: 100;
          }
        }
      }



    .dropdown-menu {
      background-color: $white;

      h5 {
      //  color: $secondary;
        font-weight: 500;
        margin-bottom: $spacer;
        text-transform: uppercase;

        a {
          padding-left: 0;
        }
      }

      ul {
        list-style-type: none;
        padding: 0;
        li {
           padding: 0;
          a
          {
            display: inline-block;
            position: relative;
            &:before {
             // background-color: $secondary;
              bottom: 0;
              content: "";
              height: 2px;
              left: 0;
              position: absolute;
              transition: all .3s ease-in-out;
              visibility: hidden;
              width: 0;
            }

            &:hover:before {
              visibility: visible;
              width: 100%;
            }
          }


        }
        .dropdown-item {
          padding: 0 0 .2em 0;
          font-size: 1rem;
          color: $black;
          font-weight: 400;
          //display: inline-block;
          //position: relative;
          @include media-breakpoint-up(lg) {
            font-weight: 100;
          }
          &:hover {
            background-color: transparent;
          }

          //&:after {
          //  content: '';
          //  position: absolute;
          //  width: 100%;
          //  transform: scaleX(0);
          //  height: 2px;
          //  bottom: 0;
          //  left: 0;
          //  background-color: $secondary;
          //  transform-origin: bottom right;
          //  transition: transform 0.25s ease-out;
          //}
          //&:hover:after {
          //  transform: scaleX(1);
          //  transform-origin: bottom left;
          //}
        }
      }

      //a {
      //  padding: .25em .5em;
      //  color: $blue;
      //  font-family: $headings-font-family;
      //  font-size: 1rem;
      //  &:hover {
      //    color: $white;
      //  }
      //
      //  &:before  {
      //    font-family: 'FontWebshop';
      //    font-style: normal;
      //    display: inline-block;
      //    font-weight: normal;
      //    font-size: .65rem;
      //    content: "\f054";
      //    padding-right: .25em;
      //    color: $blue;
      //    //-webkit-font-smoothing: antialiased;
      //    //-moz-osx-font-smoothing: grayscale;
      //  }
      //
      //}



      //.fa {
      //  font-size: .65rem;
      //  margin-right: .1em;
      //}
    }


  }

  // navbar dropdown



.dropdown-toggle,  .nav-link  {
  color: $black;
  font-size: 1.1rem;
  font-weight: 400;
  padding: .35em .25em 0;
  text-decoration: none;
  @include transition-all();
  text-transform: uppercase;

  //@include media-breakpoint-up(lg) {
  //  font-weight: 500;
  //}

  &:hover {
    color: $gray-600;
  }

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.1rem;
  }

  @include media-breakpoint-up(xxxl) {
    font-size: 1.2rem;
  }

  .fa-angle-down {
    font-size: .85rem;
    vertical-align: middle;
  }

  .fa-exclamation-triangle {
    vertical-align: text-bottom;
  }
}

  .dropdown-menu {
    padding: 0;
    position: absolute;
    width: 100%;
    border-radius: 0;

    @include media-breakpoint-up(lg) {
   //   position: unset;
      width: unset;
    }

    a {
      font-size: 1.2rem;
      padding: .2em .5em;

      //&:before  {
      //  color: $indigo;
      //  content: "\f054";
      //  display: inline-block;
      //  font-family: 'FontWebshop';
      //  font-size: .65rem;
      //  font-style: normal;
      //  font-weight: normal;
      //  padding-right: .25em;
      //  //-webkit-font-smoothing: antialiased;
      //  //-moz-osx-font-smoothing: grayscale;
      //}

    }
    .dropdown-item {
      color: $primary;
    }
  }


//  li {
//    padding-left: 0;
//border: 1px solid red;
//  }

  //.nav-link {
  //  color: $white;
  //  text-transform: uppercase;
  //  font-family: $headings-font-family;
  //  @include transition-all;
  //  &:hover {
  //    color: $gray-700;
  //    cursor: pointer;
  //  }
  //}

}


  .login {
    color: $white;
    padding-top: $grid-gutter-width * 2;
    .fa {
      font-size: 2.2rem;
      margin-top: .1em;
    }

    p {
      margin-bottom: .5em;
    }

    span {
      display: block;
      font-size: .85rem;
      line-height: 1;
      margin-left: .5em;
      text-transform: uppercase;

      &:hover {
        color: $gray-600;
        cursor: pointer;
      }

      //&:first-child {
      //  padding-bottom: .2em;
      //  margin-bottom: .2em;
      //  line-height: .8;
      //  border-bottom: 1px solid $white;
      //}

    }


    //.user-dropdown {
    //  //display: block;
    //  //position: absolute;
    // // margin-top: 1em;
    ////  margin-left: 3em;
    //  color:  $white;
    //  &:hover {
    //    cursor: pointer;
    //    color: $gray-600;
    //  }
    //
    //  .fa-angle-down {
    //    font-size: 1rem;
    //  }
    //
    //  span {
    //    padding: .5em 0 .5em .5em;
    //    margin-left: 0;
    //    font-weight: 700;
    //    background-color: $secondary;
    //    color: $white;
    //    text-transform: uppercase;
    //  }
    //}

// login dropdown

    .dropdown-menu {
      background-color: $white;

      .dropdown-item {
        padding: 0.2em 0.5em;
      }

      a {
        color: $blue;
        font-family: $headings-font-family;
        font-size: 1rem;
        padding: .25em .5em;
        &:hover {
          color: $white;
        }

        &:before  {
          color: $blue;
          content: "\f054";
          display: inline-block;
          font-family: 'FontWebshop';
          font-size: .65rem;
          font-style: normal;
          font-weight: normal;
          padding-right: .25em;
          //-webkit-font-smoothing: antialiased;
          //-moz-osx-font-smoothing: grayscale;
        }

      }

      .fa {
        font-size: .65rem;
        margin-right: .1em;
      }
    }
  }

  .fa-shopping-cart {
    color: $white;
    margin-left: .2em;
    margin-top: .05em;
   // font-size: 2.4rem;
  }


  .bg-secondary {
    margin-left: -.75em;
    margin-top: -2.3em;
}

  .bg-favorit {
    // position: absolute;
    background-color: $green-700;
    margin-left: -2.45em;
    margin-top: -2.3em;
  }

// SEARCH INPUT ///////////////////////////////////////////////////////

  .show-input {

    .search-bar {
      left: 5%;
      position: fixed;
      // right: 0;
      top: 60px;
      width: 90%;
      z-index: 20;

      @include media-breakpoint-up(lg) {
        left: 0;
        margin-top: $grid-gutter-width / 2;
        position: relative;
        top: 0;
      }
  }

    .search-bar-top {
      position: relative;
      top: 0px;
    }

    .scrolled-navbar {
      // background-color: $orange;
      height: 125px;

      @include media-breakpoint-up(lg) {
        height: 50px;
      }

    }

  }


  .search-bar {
    background-color: $gray-100;
    border: 1px solid transparent;
    border-radius: 20px;
    margin-top: $grid-gutter-width / 2;
    padding: 3px 5px 5px 10px;
    @include transition-all;
    position: relative;
    width: 100%;
    z-index: 2;

    @include media-breakpoint-up(lg) {
      margin-left: auto;
      margin-right: auto;
      max-width: 50em;
    }

    @include media-breakpoint-up(xxl) {
      border-radius: 30px;
      padding: 8px 10px 10px 20px;
    }

    @include media-breakpoint-up(xxxl) {
      height: 60px;
    }

    &:focus-within {
      // box-shadow: $gray-200 0 2px 4px;
      background-color: lighten($cyan, 28%);
      border-color: $gray-200;
    }
    // border: 1px solid $gray-200;
    &:hover {
      >.search-input {
        caret-color: $danger;
        transition: width 0.4s linear;
      }
      >.search-icon {
        background: $gray-500;
        cursor: pointer;
      }
    }
  }




  .search-input {
   // color: $white;
    background: none;
    border: 0;
    // caret-color: $black;
    font-size: .95rem;
    line-height: 40px;
    outline: 0;
    transition: width 0.4s linear;
    width: 87%;

    @include media-breakpoint-up(sm) {
      font-size: 1.2rem;
      width: 80%;
    }

    @include media-breakpoint-up(md) {
      font-size: 1.3rem;
      width: 80%;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1.3rem;
      width: 80%;
    }

    @include media-breakpoint-up(xl) {
      font-size: 1.4rem;
      width: 85%;
    }

    @include media-breakpoint-up(xxxl) {
      width: 90%;
    }
  }
  .search-icon {
    background: $gray-300;
    border-radius: 50%;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    float: right;
    height: 40px;
    // color: $primary;
    text-decoration: none;
    @include transition-all;
    width: 40px;

    .fa-search {
      margin-top: .2em
    }

    //&:hover {
    //  background-color: $gray-400;
    //}

  }


// SEARCH CONTAINER ///////////////////////////////////////////////////////

  .search-result {
   // z-index: 9999;
  }

  .search-container {
    background-color: $white;
    padding: 1em;
    @include border-radius();
    @include box-shadow($box-shadow-lg);
    @include transition-all;
  //  background: linear-gradient(8deg, rgba(142,138,214,.8), rgba(73,66,60, 0.8) 50.71%);
    background-image: linear-gradient(lighten($teal, 35%), lighten($blue, 20%));

    @include media-breakpoint-up(lg) {
      margin-top: -2em;
    }



    //position: absolute;
    //top: 20px;
    //left: 0;
    //right: 0;
    //bottom: 0;



    //@include media-breakpoint-up(lg) {
    //  position: unset;
    //}



    dt {
      border-bottom: 3px solid lighten($blue, 20%);
      //  margin-bottom: $spacer;
      text-transform: uppercase;
      color: $primary;
    }


    dl {
      &:nth-child(2) {
        border-left: 1px solid lighten($blue, 20%);
        border-right: 1px solid lighten($blue, 20%);
      }


    }


    dd {
      border-bottom: 1px solid lighten($blue, 30%);
      margin-bottom: 0;
      padding: $spacer 0 $spacer $spacer;
      @include transition-all;
      background-color: $white;

      &:hover {
        cursor: pointer;
        color: $indigo;
        @include box-shadow(inset 0 0 2px $indigo)
      }
    }

    .search-arrow {

      dd {
        &:before  {
          font-family: 'FontWebshop';
          font-style: normal;
          display: inline-block;
          font-weight: normal;
          font-size: .65rem;
          content: "\f054";
          padding-right: .25em;
          color: $indigo;
          //-webkit-font-smoothing: antialiased;
          //-moz-osx-font-smoothing: grayscale;
        }
      }
    }



    a {
    color: $gray-900;
    }

    h4 {
      font-family: $headings-font-family;
      font-size: 1rem;
    }

  }


// NAVIGATION ///////////////////////////////////////////////////////
  @media all and (min-width: 992px) {
    .dropdown-large{
      min-width: 500px;
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width * 2;

      li {
        font-size: .95rem;
        font-family: $headings-font-family;
      }

      .title {
        margin-bottom: $spacer;
        text-transform: uppercase;
        font-size: 1rem;
      }

      a {
        line-height: 1;
        padding-left: 0;
        text-decoration: none;
      }
    }
  }



  // PHONE ///////////////////////////////////////////////////////
  .phone-container {
    a {
      color: $secondary;
      @include transition-all();

      &:hover {
        color: $black;
      }
    }

    .fa-mobile-phone {
      color: $secondary !important;
      &:hover {
        color: $black !important;
      }

      font-size: 2.4rem;
      vertical-align: text-bottom;

      @include media-breakpoint-up(xl) {
        font-size: 2.8rem;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 2.7rem;
      }

      @include media-breakpoint-up(xxxl) {
        font-size: 2.8rem;
      }
    }
  }

}



