input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

html, body {
  -webkit-touch-callout: none; // * iOS Safari */
  -webkit-user-select: none; // * Safari */
  -khtml-user-select: none; // * Konqueror HTML */
  -moz-user-select: none; // * Old versions of Firefox */
  -ms-user-select: none; // * Internet Explorer/Edge */
  user-select: none; // * Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}


:host{
  box-sizing: border-box;
  display: block;
  height: 100%;
}

//.fa-facebook {
//  color: #0166E2;
//}
