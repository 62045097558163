@import './node_modules/bourbon/core/bourbon';

$font-file-path: "/assets/fonts/";

// * ======= FONTS TEXT ======================================== */
  // 100-Thin, 200-ExtraLight, 300-Light, 400-Regular, 500-Medium, 600-SemiBold, 700-Bold, 800-ExtraBold, 900-Black

  // $font-weight-light:                           300;  // 100, 200, 300, 400
  // $font-weight-normal:                          400;  // 200, 300, 400, 500
  // $font-weight-bold:                            700;  // 300, 400, 500, 600, 700, 800, 900

  $font-weight-light-suffix: "";
  @if $font-weight-light == 100 {
    $font-weight-light-suffix: "-Thin";
  } @else if $font-weight-light == 200 {
    $font-weight-light-suffix: "-ExtraLight";
  } @else if $font-weight-light == 300 {
    $font-weight-light-suffix: "-Light";
  } @else if $font-weight-light == 400 {
    $font-weight-light-suffix: "-Regular";
  } @else {
  }

  $font-weight-normal-suffix: "";
  @if $font-weight-normal == 200 {
    $font-weight-normal-suffix: "-ExtraLight";
  } @else if $font-weight-normal == 300 {
    $font-weight-normal-suffix: "-Light";
  } @else if $font-weight-normal == 400 {
    $font-weight-normal-suffix: "-Regular";
  } @else if $font-weight-normal == 500 {
    $font-weight-normal-suffix: "-Medium";
  } @else {
  }

  $font-weight-bold-suffix: "";
  @if $font-weight-bold == 300 {
    $font-weight-bold-suffix: "-Light";
  } @else if $font-weight-bold == 500 {
    $font-weight-bold-suffix: "-Regular";
  } @else if $font-weight-bold == 500 {
    $font-weight-bold-suffix: "-Medium";
  } @else if $font-weight-bold == 600 {
    $font-weight-bold-suffix: "-SemiBold";
  } @else if $font-weight-bold == 700 {
    $font-weight-bold-suffix: "-Bold";
  } @else if $font-weight-bold == 800 {
    $font-weight-bold-suffix: "-ExtraBold";
  } @else if $font-weight-bold == 900 {
    $font-weight-bold-suffix: "-Black"
  } @else {
  }


  @include font-face( $font-family-sans-serif, $font-file-path  + $font-family-sans-serif +"/"+ $font-family-sans-serif + $font-weight-light-suffix, ("woff2", "woff")) {font-style: normal; font-weight: $font-weight-light;}

  @include font-face( $font-family-sans-serif, $font-file-path  + $font-family-sans-serif +"/"+ $font-family-sans-serif + $font-weight-light-suffix + "Italic", ("woff2", "woff")) {font-style: italic; font-weight: $font-weight-light;}

  @include font-face( $font-family-sans-serif, $font-file-path  + $font-family-sans-serif +"/"+ $font-family-sans-serif +$font-weight-normal-suffix, ("woff2", "woff")) {font-style: normal; font-weight: $font-weight-normal;}

@include font-face( $font-family-sans-serif, $font-file-path  + $font-family-sans-serif +"/"+ $font-family-sans-serif + $font-weight-normal-suffix +"Italic", ("woff2", "woff")) {font-style: italic; font-weight: $font-weight-normal;}

@include font-face( $font-family-sans-serif, $font-file-path  + $font-family-sans-serif +"/"+ $font-family-sans-serif +$font-weight-bold-suffix, ("woff2", "woff")) {font-style: normal; font-weight: $font-weight-bold;}

    @include font-face( $font-family-sans-serif, $font-file-path  + $font-family-sans-serif +"/"+ $font-family-sans-serif + $font-weight-bold-suffix +"Italic", ("woff2", "woff")) {font-style: italic; font-weight: $font-weight-bold;}


// * ======= HEADING FONTS FAMILY ======================================== */

@if $headings-font-family == $font-family-sans-serif {
$headings-font-family: $font-family-sans-serif;

} @else {

  $headings-font-weight-suffix: "";

  @if $headings-font-weight == 100 {
    $headings-font-weight-suffix: "-Thin";
  } @else if $headings-font-weight == 200 {
    $headings-font-weight-suffix: "-ExtraLight";
  } @else if $headings-font-weight == 300 {
    $headings-font-weight-suffix: "-Light";
  } @else if $headings-font-weight == 400 {
    $headings-font-weight-suffix: "-Regular";
  } @else if $headings-font-weight == 500 {
    $headings-font-weight-suffix: "-Medium";
  } @else if $headings-font-weight == 600 {
    $headings-font-weight-suffix: "-SemiBold";
  } @else if $headings-font-weight == 700 {
    $headings-font-weight-suffix: "-Bold";
  } @else if $headings-font-weight == 800 {
    $headings-font-weight-suffix: "-ExtraBold";
  } @else if $headings-font-weight == 900 {
    $headings-font-weight-suffix: "-Black";
  } @else {
  }

  @include font-face( $headings-font-family, $font-file-path  + $headings-font-family +"/"+ $headings-font-family + $headings-font-weight-suffix, ("woff2", "woff")) {font-style: normal; font-weight: $headings-font-weight;}

  @include font-face( $headings-font-family, $font-file-path  + $headings-font-family +"/"+ $headings-font-family +  $headings-font-weight-suffix +"Italic", ("woff2", "woff")) {font-style: italic; font-weight: $headings-font-weight;}


  @include font-face( $headings-font-family, $font-file-path  + $headings-font-family +"/"+ $headings-font-family + "-Regular", ("woff2", "woff")) {font-style: normal; font-weight: 500;}
  @include font-face( $headings-font-family, $font-file-path  + $headings-font-family +"/"+ $headings-font-family +  "-Italic", ("woff2", "woff")) {font-style: italic; font-weight: 500;}

  @include font-face( $headings-font-family, $font-file-path  + $headings-font-family +"/"+ $headings-font-family + "-Regular", ("woff2", "woff")) {font-style: normal; font-weight: 500;}
  @include font-face( $headings-font-family, $font-file-path  + $headings-font-family +"/"+ $headings-font-family +  "-Italic", ("woff2", "woff")) {font-style: italic; font-weight: 500;}
}

