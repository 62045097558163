@mixin transition-all($what: all, $time: 0.15s, $how: ease-in-out) {
  -webkit-transition: $what $time $how;
  -moz-transition:    $what $time $how;
  -ms-transition:     $what $time $how;
  -o-transition:      $what $time $how;
  transition:         $what $time $how;
}

// BOX SHADOW
// Usage
// @include box-shadow(0px 2px 4px 0px rgba(0, 0, 0, 0.4), 0px 0px 6px 2px rgba(255,255,255,0.5)) ;

@mixin box-shadow($args...) {
  @each $pre in -webkit-, -moz-, -ms-, -o- {
    #{$pre + box-shadow}: $args;
  }
  #{box-shadow}: #{$args};
}

// GHOST BUTTON
// Usage
//div {
//  @include ghost-button(“Trebuchet”, 12px, #ffffff, 5px, #34dec6, 4px, 300ms, #000000 );
//}

@mixin ghost-button($font, $font-size, $font-color, $border-size, $border-color, $padding, $transition-speed, $hover-color)
{
  display:inline-block;
  text-decoration:none;
  text-transform:uppercase;
  font-family: $font;
  font-size: $font-size;
  color:$font-color;
  border:$border-size solid $border-color;
  padding:$padding;
  -webkit-transition: color $transition-speed, background $transition-speed;
  transition: color $transition-speed, background $transition-speed;
  &:hover
  {
    background:$border-color;
    color:$hover-color;
  }
}



@import "flex-mixins";
